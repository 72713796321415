<template>
  <div class="finance-detailed">
    <el-card shadow="hover" style="margin-bottom: 16px">
      <div class="row no-gutters">
        <div class="col">
          <div class="box-title">
            <span style="font-size: 16px">收入</span>
            <span> ({{ gailan.income_count || 0 }}笔)</span>
          </div>
          <div class="box-value" style="color: #e3405b;">
            <span style="font-size: 16px">￥</span>
            <span style="font-size: 32px">{{ (gailan.income || 0) | unitPrice('') }}</span>
          </div>
        </div>
        <div class="col">
          <div class="box-title">
            <span style="font-size: 16px">支出</span>
            <span> ({{ gailan.outcome_count || 0 }}笔)</span>
          </div>
          <div class="box-value" style="color: #5dbe2d;  font-size: 32px">
            <span style="font-size: 16px">￥</span>
            <span style="font-size: 32px">{{ (gailan.outcome || 0) | unitPrice('') }}</span>
          </div>
        </div>
      </div>
    </el-card>

    <en-table-layout
      :tableData="pageData.data"
      :loading="loading"
      :redundancy-height="128"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="日期范围">
          <el-date-picker
            style="width: 220px"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            size="medium"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="流水类型">
          <el-select
            size="medium"
            v-model="params.flow_type"
            placeholder="请选择流水类型"
            clearable
            style="width: 160px"
          >
            <el-option
              v-for="item in list1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="关键字">
          <el-input
            placeholder="请输入关键字"
            size="medium"
            v-model.trim="params.sn_query"
          >
            <el-select
              slot="prepend"
              size="medium"
              v-model="params.sn_type"
              placeholder="关键字类型"
              style="width: 120px;"
            >
              <el-option
                v-for="item in list2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button
            type="primary"
            :loading="loading"
            @click="getList()"
            size="small"
          >
            搜索
          </el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button
            type="primary"
            :loading="importLoading"
            @click="downZd()"
            size="small"
          >
            下载财务明细
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="入账时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="财务流水号">
          <template slot-scope="scope">{{ scope.row.sn }}</template>
        </el-table-column>
        <el-table-column
          label="财务类型"
          props="flow_type"
          :formatter="flow_type_value"
        ></el-table-column>
        <el-table-column label="来源站点" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.company_balance_log_do">平台</span>
            <span v-else>{{ scope.row.member_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="交易内容" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.order_content }}
            <span v-if="scope.row.company_balance_log_do && scope.row.company_balance_log_do.remark">
              ；平台备注：{{ scope.row.company_balance_log_do.remark}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收支金额">
          <template slot-scope="scope">
            {{ scope.row.revenue_expense_amount | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="账户余额">
          <template slot-scope="scope">
            {{ scope.row.balance_account }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              v-if="flow_type_value(scope.row)==='采购'"
              size="mini"
              type="primary"
              @click="handleStockGoods(scope.row)"
            >详情
            </el-button>
            <span v-else>——</span>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import {handleDownload} from '@/utils';
import {Foundation} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: {EnTableLayout},
  data() {
    return {
      activeName: 1,
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      txInfo: {},
      txRules: {
        a4a4: [{
          required: true,
          message: '请输入礼包名称',
          trigger: 'blur'
        }],
      },
      // 流水类型 0：充值，1：销售，2：采购，3：运费，4：提现，5：提现手续费，6：自有订单服务费，7：退款，8：购买短信费用，9：企业等级购买费用，10：其他
      list1: [],
      list2: [
        {
          value: 1,
          label: '财务流水号'
        },
        {
          value: 2,
          label: '订单编号'
        },
      ],
      gailan: {},
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        shop_name: '',
        seller_name: '',
        start_time: '',
        end_time: '',
        logi_id: '',
        pay_type: '',
        ca_type: 0,
        flow_type: '',
        sn_type: '',
        sn_query: '',
        account_type: 3,
      },

      daterangeDate: [],

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: {
        data: [],
      },
      pageData1: {
        data: [],
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近7日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近15日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近30日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    daterangeDate: {
      handler: 'daterangeDateChange',
      deep: true,
    },
  },
  created() {
    if (this.$route.query.types) {
      this.$route.query.types === 'day'
        ? this.dateSetValue(1)
        : this.dateSetValue(2);
    }
  },
  activated() {
    this.GET_AccountList();
    this.GET_getGaiLan();
  },
  mounted() {
    // this.params.coll_type = this.$route.query.types === "day" ? "1" : "2";
    this.GET_getFlowTypeList();
    this.GET_getGaiLan();
    this.GET_AccountList();
  },
  methods: {
    dateSetValue(val) {
      this.daterangeDate = [];
      switch (val) {
        case 1: //日
          this.daterangeDate.push(this.getDay(0));
          this.daterangeDate.push(this.getDay(0));
          return;
        case 2: //月
          var Day = Foundation.unixToDate(
            new Date().setDate(1) / 1000,
            'yyyy-MM-dd'
          );
          this.daterangeDate.push(Day);
          this.daterangeDate.push(this.getDay(-1));
          return;
      }
    },
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + '/' + tMonth + '/' + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = '0' + month;
      }
      return m;
    },
    daterangeDateChange(val) {
      if (this.daterangeDate && this.daterangeDate.length > 0) {
        this.params.start_time =
          new Date(this.daterangeDate[0]).getTime() / 1000;
        this.params.end_time = new Date(this.daterangeDate[1]).getTime() / 1000;
      } else {
        this.params.start_time = '';
        this.params.end_time = '';
      }
      // this.params.start_time = val ? val[0] / 1000 : "";
      // this.params.end_time = val ? val[1] / 1000 : "";
    },
    flow_type_value(row, column) {
      let obj = this.list1.find((value, index, arr) => {
        return row.flow_type == value.code;
      });
      if (obj) {
        let txt=''
        if(row.company_balance_log_do) txt='（平台充值）'
        return obj.name + txt;
      }
    },
    getList() {
      this.GET_getGaiLan();
      this.GET_AccountList();
    },
    GET_getGaiLan() {
      let pa = {};
      pa.start_time = this.params.start_time;
      pa.end_time = this.params.end_time;
      pa.flow_type = this.params.flow_type;
      pa.sn_type = this.params.sn_type;
      pa.sn_query = this.params.sn_query;
      pa.account_type = this.params.account_type;
      API_account.getGaiLan(pa).then((res) => {
        this.gailan = res;
      });
    },

    downZd(row) {
      if (!row) {
        this.importLoading = true;
      }
      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }
      const params = this.MixinClone(this.params)
      delete params.page_no;
      delete params.page_size;

      API_account.getAccountFlowList(params).then((response) => {
        response.forEach((item) => {
          item.create_date = Foundation.unixToDate(
            item.create_date,
            'yyyy-MM-dd hh:mm:ss'
          );
          const result = this.list1.find((value, index, arr) => {
            return value.code == item.flow_type;
          });
          if(item.company_balance_log_do && item.company_balance_log_do.remark){
            let text = '；平台备注：'+item.company_balance_log_do.remark
            item.order_content += text
          }
          if(item.company_balance_log_do) item.member_name = '平台'
          if (result) {
            let txt=''
            if(item.company_balance_log_do) txt='（平台充值）'
            item.flow_type = result.name + txt;
          } else {
            item.flow_type = '！异常:' + item.flow_type;
          }
        });
        let tHeaders = [
          '入账时间',
          '财务流水号',
          '财务类型',
          '来源站点',
          '交易内容',
          '收支金额',
          '账户余额',
        ];
        let filterVals = [
          'create_date',
          'sn',
          'flow_type',
          'member_name',
          'order_content',
          'revenue_expense_amount',
          'balance_account',
        ];
        handleDownload(response, tHeaders, filterVals, '财务明细账单');
        this.importLoading = false;
      });
    },

    /**获取流水类型信息 */
    GET_getFlowTypeList() {
      API_account.getFlowTypeList({use_type: 3}).then((response) => {
        this.list1 = response;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      this.GET_AccountList();
    },

    GET_AccountList() {
      this.loading = true;

      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }

      API_account.getAccountFlowPage(this.params).then((response) => {
        this.loading = false;
        this.pageData = response;
        // this.tableData = response.data;

        if (response.data.length === 0 && this.params.page_no > 1) {
          this.params.page_no--;
          this.GET_AccountList();
        }
      });
    },

    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement() {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type,
        }).then((res) => {
          this.$message.success('发起结算成功');
          this.GET_AccountList();
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods(row) {
      // this.$router.push("/finance/detail/" + row. sn);
      let route = this.$route.path.split('/')[2];
      this.$router.push({path: `/finance/${route}/detail/${row.id}`});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>








